import '../button.scss';
import './styles.scss';

import React from 'react';
import cx from 'classnames';
import useHover from '@assets/scripts/hooks/useHover';
import iconArrowSmall from '@assets/icons/arrow-small.svg';

const ButtonExternal = ({ href, className, text, ...otherProps }) => {
	const rootClass = cx('btn', 'btn-external', className);

	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<a
			className={rootClass}
			href={href}
			target="_blank"
			rel="noopener noreferrer"
			onMouseEnter={() => hoverOn()}
			onMouseLeave={() => hoverOff()}
			data-target
			{...otherProps}
		>
			<span className="btn__icon">
				<svg>
					<use xlinkHref={`#${iconArrowSmall.id}`} />
				</svg>
			</span>
			<span>
				<div data-boldproof-text={text}>{text}</div>
			</span>
		</a>
	);
};

export default ButtonExternal;
