import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
// import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#17A345';
const footerRelatedLinks = [
	{
		title: 'Ackermann',
		url: '/en/projects/ackermann-branding/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/hesav/hesav_hero_EN.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: { eq: "projects/hesav/hesav_hero_mobile.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/hesav/hesav_3_EN.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/hesav/hesav_4_EN.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/hesav/hesav_5_EN.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/hesav/hesav_6.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1500, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/hesav/hesav_7.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1500, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/hesav-haute-ecole-vaudoise-sante/',
					lang: 'fr',
				},
			]}
			title="HESAV - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projects',
					link: '/en/projects/',
				}}
				title="HESAV"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Educate medical staff to offer the best treatment by
							focusing on the real needs of patients.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Design strategy',
								'User research',
								'Digital strategy',
								'Digital design',
								'Web development',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							Dispersing information across a multitude of
							websites, very diverse audiences with different
							needs and a rich set of entities (academic,
							research, training) made it challenging to implement
							an effective solution.
						</p>
						<h3>Our answer</h3>
						<p>
							Driven by a desire to keep both students and
							researchers at the heart of the process, we based
							our project on user research. Interviews,
							observational studies and data analysis allowed us
							to build an effective, fact-based response. Such an
							approach enabled us to gather most of the
							information on a single web platform through user
							paths adapted to each typology.
						</p>
						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.hesav.ch/"
								text="Visit the website"
							/>
						</div>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div>
						<Image
							{...data.image_3.childImageSharp}
							alt="HESAV process in 3 steps"
						/>
					</div>
				</div>

				<Video
					className="col-full"
					src="f0f34d351aa5a5ebfc78f071958f0002"
					alt="Animation Homepage"
				/>

				{/***** INTRODUCTION CONTEXTES *****/}
				<div className="box text-content" data-animation-page>
					<div className="list-two-columns">
						<div className="text-content">
							<h3>Different needs, different experiences</h3>
						</div>
						<div className="text-content">
							<p>
								We simplified the menu structure. Whether the
								user is a student, a prospective student, a
								researcher or a school employee, they can
								quickly find the information they’re after.
							</p>
						</div>
					</div>
				</div>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.image_6.childImageSharp}
						alt="Mobile Design"
					/>
					<Image
						{...data.image_7.childImageSharp}
						alt="Mobile Design"
					/>
				</Gallery>

				<Video
					className="col-full"
					src="3260f5aeec42665d11e9c6203b107e43"
					alt="Animation Menu"
				/>

				{/***** VALORISATION TYPO *****/}
				<div className="box text-content" data-animation-page>
					<h3>Typographical evolution</h3>
					<div className="list-two-columns align-items-center">
						<Image
							{...data.image_4.childImageSharp}
							alt="Typography"
						/>
						<Image
							{...data.image_5.childImageSharp}
							alt="Typography"
						/>
					</div>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								We also amplified the focus on the HESAV brand
								by proposing an adaptation of the logo and the
								modernization of the visual artefacts with the
								use of a singular typeface.
							</p>
						</div>
						<div className="text-content"></div>
					</div>
				</div>

				<Video
					className="col-full"
					src="ffbc7e5f2c4458c114c374b186a88e80"
				/>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
