import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
const Gallery = ({
	modifiers,
	utility,
	className,
	children,
	...otherProps
}) => {
	const baseClass = 'list-gallery';
	const modifiersClasses = modifiers ? modifiers.split(" ").map(modifier => {
		return `${baseClass}--${modifier}`;
	}) : false;
	const rootClass = cx(baseClass, className, modifiersClasses);

	return (
		<div
			className={rootClass}
			aria-label="Gallery"
			{...otherProps}
			data-animation-page
		>
			{ children }
		</div>
	);
};

Gallery.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
};

export default Gallery;
